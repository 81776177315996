<template> 
  <router-view></router-view>  

  <div class="Chat-box-open-box" @click="OpenChatBox" v-if="is_login=='1'">
        <div class="Chat-box-open-btn">
            CHAT WITH CAPROCK
        </div>
    </div>

    <div class="Chat-box-show" id="Chatbox">
        <div class="Chat-box-position">
            <div class="Chat-box-Layout">
                <div class="Chat-box-header">
                    <div class="Chat-box-header-content justify-content-between">
                      <div class="d-flex align-items-center">
                        <div class="Chat-box-header-back-icon">
                            <svg style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512"
                                xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <polygon
                                    points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
                            </svg>
                        </div>
                        <h3 class="chat-box-title ml-3">Caprock Team
                          <small v-if="admin_live==1" class="d-block"  style="color:white">Online</small>
                        </h3> 
                      </div>  
                      <div class="float-end" @click="CloseChatBox" style="color: white;cursor: pointer;">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="12px"
                          height="12px" viewBox="0 0 121.31 122.876" enable-background="new 0 0 121.31 122.876"
                          xml:space="preserve">
                          <g>
                              <path fill="#fff" fill-rule="evenodd" clip-rule="evenodd"
                                  d="M90.914,5.296c6.927-7.034,18.188-7.065,25.154-0.068 c6.961,6.995,6.991,18.369,0.068,25.397L85.743,61.452l30.425,30.855c6.866,6.978,6.773,18.28-0.208,25.247 c-6.983,6.964-18.21,6.946-25.074-0.031L60.669,86.881L30.395,117.58c-6.927,7.034-18.188,7.065-25.154,0.068 c-6.961-6.995-6.992-18.369-0.068-25.397l30.393-30.827L5.142,30.568c-6.867-6.978-6.773-18.28,0.208-25.247 c6.983-6.963,18.21-6.946,25.074,0.031l30.217,30.643L90.914,5.296L90.914,5.296z" />
                          </g>
                        </svg>
                      </div>
                    </div>
                    
                </div>

                <div class="text-center" v-if="chat_info==null">
                  <img style="margin: 0; object-fit: contain; width: 100%; height: auto;" src="@/assets/chat/chat.gif">
                  <p style="color: grey; margin-top: -25px;">Get answers via chat<br>Easy and convenient support.</p>
                  <button @click="start_chat" class="start-btn">Start a chat</button>
                </div>

                <div class="chat-box-main-content"  id="msgDiv" v-if="chat_info!=null">
                   
                    <ul ref="scrollContainer"  @scroll="handleScroll(e)" class="chat-box-main-content-inner" id="allMessages">  
                      <li   :ref="'msg_'+msg.msg_id" v-for="msg in messages" :key="msg.msg_id" :class="[(msg.msg_user_id==chat_info.chat_m_id?'my-chat':'Company-chat') ]" >
                          <div v-if="msg.msg_type==1 && msg.msg_user_id==chat_info.chat_m_id" class="my-chat-section">
                              <div class="my-chat-box">
                                  {{msg.msg_text}}  
                                  <br><small class="chat-time">{{msg.time}}
                                    <small  :ref="'msgtick_'+msg.msg_id" v-html="msg.tick"></small></small>
                              </div> 
                          </div>

                          <div :id="'msg_'+msg.msg_id" v-if="msg.msg_type==1 && msg.msg_user_id!=chat_info.chat_m_id" class="Company-chat-flex"> 
                              <div class="Company-chat-section">
                                  <div class="Company-chat-box">
                                    {{msg.msg_text}} 
                                    <br><small class="chat-time">{{msg.time}}</small> 
                                  </div>  
                              </div>
                          </div>  


                          <div v-if="msg.is_image==true && msg.msg_type==2 && msg.msg_user_id==chat_info.chat_m_id" class="my-chat-section">
                              <div class="my-chat-box">
                                  <a target="_blank" :href="msg.url" class="cFile">
                                  <img class="chatImage" :src="msg.url">
                                     </a>
                                  <br><small class="chat-time">{{msg.time}}
                                    <small  :ref="'msgtick_'+msg.msg_id" v-html="msg.tick"></small></small>
                              </div> 
                          </div>

                          <div :id="'msg_'+msg.msg_id" v-if="msg.is_image==true && msg.msg_type==2 && msg.msg_user_id!=chat_info.chat_m_id" class="Company-chat-flex"> 
                              <div class="Company-chat-section">
                                  <div class="Company-chat-box">
                                    <a target="_blank" :href="msg.url" class="cFile">
                                      <img class="chatImage"  :src="msg.url"> 
                                    </a>
                                    <br><small class="chat-time">{{msg.time}}</small> 
                                  </div>  
                              </div>
                          </div> 


                          <div v-if="msg.is_image==false && msg.msg_type==2 && msg.msg_user_id==chat_info.chat_m_id" class="my-chat-section">
                              <div class="my-chat-box">
                                  <a target="_blank" :href="msg.url" class="cFile">
                                      <svg class="filesvg" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
                                          <path d="M 6 2 C 4.9057453 2 4 2.9057453 4 4 L 4 20 C 4 21.094255 4.9057453 22 6 22 L 18 22 C 19.094255 22 20 21.094255 20 20 L 20 8 L 14 2 L 6 2 z M 6 4 L 13 4 L 13 9 L 18 9 L 18 20 L 6 20 L 6 4 z">
                                          </path>
                                      </svg>
                                      {{msg.msg_text}}
                                    </a>    
                                  <br>
                                  <small class="chat-time">{{msg.time}}
                                    <small  :ref="'msgtick_'+msg.msg_id" v-html="msg.tick"></small>
                                  </small>
                              </div> 
                          </div>

                          <div :id="'msg_'+msg.msg_id" v-if="msg.is_image==false && msg.msg_type==2 && msg.msg_user_id!=chat_info.chat_m_id" class="Company-chat-flex"> 
                              <div class="Company-chat-section">
                                  <div class="Company-chat-box">
                                    <a target="_blank" :href="msg.url" class="cFile">
                                      <svg class="filesvg"  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
                                          <path d="M 6 2 C 4.9057453 2 4 2.9057453 4 4 L 4 20 C 4 21.094255 4.9057453 22 6 22 L 18 22 C 19.094255 22 20 21.094255 20 20 L 20 8 L 14 2 L 6 2 z M 6 4 L 13 4 L 13 9 L 18 9 L 18 20 L 6 20 L 6 4 z">
                                          </path>
                                      </svg>
                                      {{msg.msg_text}}
                                    </a> 
                                    <br><small class="chat-time">{{msg.time}}</small> 
                                  </div>  
                              </div>
                          </div>  


                      </li>  
                    </ul>  
                  
                    <form id="form" @submit.prevent="send_message">
                        <div class="chat-box-footer">
                            <div class="chat-box-footer-content">
                                <div class="chat-input-box">
                                  <input style="border: 1px solid black !important;padding:5px 10px!important;border-radius: 20px!important;" v-model="input_message" id="input_message" autocomplete="off" />  
                                </div>
                                <div class="Chat-box-footer-icon">
                                    <input @change="uploadChatF($event);" type="file" class="input-type-file" id="Files">
                                    <label for="Files">
                                        <svg focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16"
                                            height="18" viewBox="0 0 16 18" class="mck-icon-upload">
                                            <path fill="#000000" fill-rule="nonzero"
                                                d="M2.79801641 16.79165738l.01279906.00769045c2.7142501 1.62062499 4.9765631.04219128 6.1885999-1.97497667.3270023-.54422324 1.55448623-2.58074521 2.79039798-4.63129344 1.25308361-2.079127 2.51480582-4.17263105 2.8555035-4.73964722.7850585-1.30655673.928768-3.12041452-1.02247594-4.29284017C12.4491118.45868356 11.49591165.61137697 10.9027726.8630112c-.85581269.36273948-1.35565745 1.08421422-1.53088846 1.37743617l-5.658368 9.39208556c-.70517184 1.17797162-.5235628 2.28122412.47371238 2.8804475.61996573.372513 1.2844719.37303855 1.87102157.00170976.42648918-.27015628.72709626-.67870968.90411746-.97371957l5.59414907-9.35431076c.14474987-.24209567.0661251-.55564877-.17547919-.70081928l-.00087665-.00052674c-.24244632-.14496057-.55649081-.06591517-.70134602.17635583l-5.59383301 9.35378475c-.09800968.16351264-.30334324.46394185-.57398283.63516794-.2604276.16495342-.50615497.16048317-.7970269-.01429032-.4921504-.2957138-.53361547-.79263778-.12370218-1.47723092l5.65857872-9.39243626c.12462827-.2086077.47507585-.71678977 1.0529514-.9620843.54569911-.23145859 1.1496441-.15277963 1.7951741.23318488 1.53886856.92464552 1.08150083 2.20688915.6716944 2.88892158-.34059233.56684084-1.60217382 2.65971354-2.85476605 4.7384199-1.23640317 2.05096895-2.46402775 4.08812214-2.79103011 4.63234546-.59595756.99183994-2.25554543 3.13550123-4.7874873 1.62370223l-.0112211-.00674232c-1.13350634-.68107932-1.6541578-1.56042484-1.5915647-2.68804318.03917501-.7073837.31716069-1.51157536.80397927-2.32535185l4.75213674-7.90491222c.14548655-.24213028.06728318-.5563847-.17467177-.70176591-.24213028-.14548655-.5563847-.06728319-.7017659.17467177l-4.75276886 7.9059642c-.57201106.95635488-.8995258 1.92240601-.94810117 2.79476154-.05812167 1.04630744.2573944 2.52281778 2.0866089 3.62192074z">
                                            </path>
                                        </svg>
                                    </label>
                                </div> 
                                <button class="Chat-box-footer-icon send-icon" style="background: none;border: none;padding: 4px 8px 4px 0px;" type="submit">
                                    <svg focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="16"
                                        height="18" viewBox="0 0 15 15">
                                        <path class="km-custom-widget-fill" fill="#000000" fill-rule="evenodd"
                                            d="M7.878 7.858l-6.032.584-1.588 5.076a.787.787 0 0 0 1.097.944l13.22-6.401a.752.752 0 0 0 0-1.353L1.364.302a.787.787 0 0 0-1.096.944l1.587 5.077 6.021.584a.475.475 0 0 1 0 .946l.002.005z">
                                        </path>
                                    </svg>
                                </button> 
                            </div>
                           <!--  <div style="color:grey;text-align:center;font-size: 12px;padding: 5px;width: 100%">Powered By MGT</div>  -->
                        </div>
                    
                    </form>
                </div> 

            </div>
        </div>
    </div>
</template>
  
 
<script> 

  import { Options, Vue } from 'vue-class-component';    
  import Common from '@/services/common.js'; 
  import 'bootstrap';  
  // import SocketioService from './services/socketio.js';  
  import { io } from "socket.io-client"; 
  import moment from 'moment';

  import Axios from "axios";

  @Options({
    components: {   
    } 
  })

  


  export default class App extends Vue { 
    STORAGE_KEY = "caprock-local";  
    
    messages  = [];
    page_no   = 1;
    total_page= 1;
    chat_info   = null;
    socket;
    input_message;
    unread_msgs = [];
    is_login =localStorage.getItem('is_login') || 0;


    s_tick_svg = `<svg tick xmlns="http://www.w3.org/2000/svg" width="20" height="20" version="1.1" viewBox="0 0 700 700" class="single-tick">
      <g transform="matrix(1.1939 0 0 1.1939 -62.173 15.961)">
          <g class="tick-1">
            <path d="m92.961 244.72c-11.762 12.32-11.762 31.359 0 43.121l118.16 118.16c6.1602 6.1602 14 8.9609 21.281 8.9609 7.8398 0 15.68-2.8008 21.281-8.9609l21.84-21.281-140-140c-11.762-11.758-30.805-11.758-42.562 0z"/>
            <path d="m376.32 284.48 87.359-87.922c12.32-11.762 12.32-31.359 0-43.121-11.762-11.762-31.359-11.762-43.121 0l-87.918 87.922z"/>
          </g>
        <path class="tick-2" d="m607.04 153.44c-11.762-11.762-30.801-11.762-43.121 0l-188.16 188.16-96.883-96.883c-11.762-11.762-30.801-11.762-43.121 0s-11.762 30.801 0 43.121l118.16 118.16c6.1602 6.1602 13.441 8.9609 21.281 8.9609 7.8398 0 15.68-2.8008 21.281-8.9609l209.44-209.44c12.879-11.758 12.879-30.797 1.1172-43.117z"/>
      </g>
      </svg>`;

      d_tick_svg = `<svg tick xmlns="http://www.w3.org/2000/svg" width="20" height="20" version="1.1" viewBox="0 0 700 700" class="double-tick">
      <g transform="matrix(1.1939 0 0 1.1939 -62.173 15.961)">
          <g class="tick-1">
            <path d="m92.961 244.72c-11.762 12.32-11.762 31.359 0 43.121l118.16 118.16c6.1602 6.1602 14 8.9609 21.281 8.9609 7.8398 0 15.68-2.8008 21.281-8.9609l21.84-21.281-140-140c-11.762-11.758-30.805-11.758-42.562 0z"/>
            <path d="m376.32 284.48 87.359-87.922c12.32-11.762 12.32-31.359 0-43.121-11.762-11.762-31.359-11.762-43.121 0l-87.918 87.922z"/>
          </g>
        <path class="tick-2" d="m607.04 153.44c-11.762-11.762-30.801-11.762-43.121 0l-188.16 188.16-96.883-96.883c-11.762-11.762-30.801-11.762-43.121 0s-11.762 30.801 0 43.121l118.16 118.16c6.1602 6.1602 13.441 8.9609 21.281 8.9609 7.8398 0 15.68-2.8008 21.281-8.9609l209.44-209.44c12.879-11.758 12.879-30.797 1.1172-43.117z"/>
      </g>
      </svg>`;

      b_tick_svg = `<svg tick xmlns="http://www.w3.org/2000/svg" width="20" height="20" version="1.1" viewBox="0 0 700 700" class="blue-tick">
      <g transform="matrix(1.1939 0 0 1.1939 -62.173 15.961)">
          <g class="tick-1">
            <path d="m92.961 244.72c-11.762 12.32-11.762 31.359 0 43.121l118.16 118.16c6.1602 6.1602 14 8.9609 21.281 8.9609 7.8398 0 15.68-2.8008 21.281-8.9609l21.84-21.281-140-140c-11.762-11.758-30.805-11.758-42.562 0z"/>
            <path d="m376.32 284.48 87.359-87.922c12.32-11.762 12.32-31.359 0-43.121-11.762-11.762-31.359-11.762-43.121 0l-87.918 87.922z"/>
          </g>
        <path class="tick-2" d="m607.04 153.44c-11.762-11.762-30.801-11.762-43.121 0l-188.16 188.16-96.883-96.883c-11.762-11.762-30.801-11.762-43.121 0s-11.762 30.801 0 43.121l118.16 118.16c6.1602 6.1602 13.441 8.9609 21.281 8.9609 7.8398 0 15.68-2.8008 21.281-8.9609l209.44-209.44c12.879-11.758 12.879-30.797 1.1172-43.117z"/>
      </g>
      </svg>`;
     
    token = '';  
    session = JSON.parse(localStorage.getItem(this.STORAGE_KEY) || "{}");

    isTabFocused =true; // Default value is true
    admin_live = 0;

    handleVisibilityChange() {
      if (document.visibilityState === "visible") {
        setTimeout(() => {
          this.checkNewMessageVisibility();
        },800);
        this.isTabFocused=true;
      }else{
        this.isTabFocused=false;
      } 
    }

    data () {     
      if(this.session){
        var js =this.session;
        this.token=js['access-token'];   
        if (typeof this.token!=='undefined' &&  this.token!=='') { 
          /*this.get_documents(); */
        }
      }else{
        this.token='';  
        this.logout();
      } 

      // console.log(localStorage.getItem('is_login') || 0);
      return {  
        token:this.token,
        is_login:localStorage.getItem('is_login') || 0,
        messages:this.messages,
        chat_info:this.chat_info, 
        tick_svg:this.tick_svg ,
        isTabFocused:this.isTabFocused,
        admin_live:this.admin_live
      }
    }   

    
    isInViewport(element) {
      if(typeof element!='undefined' && typeof element!='undefined'){
        var b = element[0].getBoundingClientRect();
        return (
          b.top >= 0 &&
          b.left >= 0 &&
          b.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          b.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }  
    }

    beforeUnmount(){
      document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }

    async mounted() {  
      await this.get_chat_info(); 
      // this.socket = io("http://192.168.1.4:3000");
      this.socket = io("https://devcommunicate.caprock.com.au");
      this.socket.emit('login',{userToken:this.token}); 
      
      this.socket.on('read message', (msg) =>{ 
          /*console.log(msg);*/      
          if (typeof msg.msg_id!='undefined' && msg.msg_id!=null && msg.msg_id!='' && typeof msg.status!='undefined' && msg.status!=null && msg.status!='') {
              this.update_msg_status(msg.msg_id,msg.status);
          } 
          /*On Oponnent online */
          if (typeof msg.multi_status!='undefined' && msg.multi_status!=null && msg.multi_status!=''  && typeof msg.msgs!='undefined' && msg.msgs!=null && msg.msgs!='') {
              var mss= msg.msgs;
              mss = mss.split(',');
              for (var i =0; i<mss.length ;i++) { 
                this.update_msg_status(mss[i],msg.multi_status); 
              }  
          }
      });
      this.socket.on('chat messagedelete', (msg) =>{ 
          // /*console.log(msg);*/      
          if (typeof msg.msg_id!='undefined' && msg.msg_id!=null && msg.msg_id!='' && typeof msg.chat_id!='undefined' && msg.chat_id!=null && msg.chat_id!='') {
            this.remove_msg(msg.msg_id,msg.chat_id);
          } 
      });

      this.socket.on('online', (data) =>{ 
          if (data.length>0) {
              for (var i = data.length - 1; i >= 0; i--) { 
                var hasAdmin = this.chat_info.masters.indexOf(data[i]) != -1;
                if (hasAdmin) {
                  this.admin_live=1;
                }  
              }
          }
      });
      this.socket.on('offline', (data) =>{  
        if (typeof this.chat_info!='undefined' && typeof this.chat_info.masters!=='undefined' && this.chat_info.masters!='') {
          var hasAdmin = this.chat_info.masters.indexOf(data.user_id) != -1;
          if (hasAdmin) {
            this.admin_live=0;
          } 
        }   
      });


      if (typeof document.hidden !== "undefined") { 
        document.addEventListener("visibilitychange", this.handleVisibilityChange);
      }
      



      this.socket.on("chat message", async (msg) => {  
        if (typeof msg.msg!='undefined' && msg.msg!=null && msg.msg!='') { 


            var bottomJavanu =0 
            if(Math.ceil(this.$refs.scrollContainer.offsetHeight + this.$refs.scrollContainer.scrollTop)>=(this.$refs.scrollContainer.scrollHeight)){
              bottomJavanu=1;
            }else{
              // alert('New Message');
            }  


          msg.time =moment.unix(msg.time).format('h:mm A'); 
          
          
          
          if(msg.msg_user_id!=this.chat_info['chat_m_id']){
            msg.tick = this.s_tick_svg;
            msg.is_read=false;
            this.unread_msgs.push(msg); 
          }else{
            msg.tick = this.s_tick_svg;
            msg.is_read=true;
          }
          msg.is_image=await this.checkImageUrl(msg.url);
          // console.log(msg);
          this.messages.push(msg); 
          setTimeout(() => {
            this.checkNewMessageVisibility();
          },800);

          if (this.chat_info.chat_m_id==msg.my_id) { 
            this.bottomMe();
          }
          
           
          if(bottomJavanu==1){
            this.bottomMe();
          }else{
            // alert('New Message');
          } 
        } 
      });
 
      if(typeof this.chat_info!='undefined' && this.chat_info!=null && typeof this.chat_info.chat_id!='undefined'){
        var data = {
            chat_id     :   parseInt(this.chat_info.chat_id), 
            token       :   this.token,
            message     :   '',
        } 
        this.socket.emit('chat message', data); 
      }
    }  

 
    remove_msg(msg_id,chat_id){
      // alert(msg_id);
      let updateStudentList = this.messages.filter((el) => el.msg_id !== msg_id);
      this.messages = updateStudentList;
    }


    update_msg_status(msg_id,status){ 
      if ((typeof status=='undefined' || status==null || status=='') || (typeof msg_id=='undefined' || msg_id==null || msg_id=='')) {
        return false;
      }else{ 
        const messageElement = this.$refs[`msgtick_${msg_id}`];
        if(typeof messageElement!='undefined' && typeof messageElement[0]!='undefined'){
          if (status==2) {
            messageElement[0].innerHTML=this.d_tick_svg;  
          }
          if (status==3) {
            messageElement[0].innerHTML=this.b_tick_svg;
          } 

        } 
           
      } 
  }

 
    bottomMe(){
      if (this.$refs.scrollContainer) { 
        setTimeout(() => { 
          this.$refs.scrollContainer.scrollTop = this.$refs.scrollContainer.scrollHeight;
        },100);
      }
    }

    logout(){ 
      localStorage.clear();
      this.token='';   
      location.href='/sign-in'; 
    }

    send_message(){  
      if (typeof this.input_message!='undefined' && this.input_message!=null && this.input_message!='') { 
          var data = {
              chat_id     :   parseInt(this.chat_info.chat_id), 
              to_id       :   null,
              my_id       :   parseInt(this.chat_info.chat_m_id), 
              token       :   this.token,
              message     :   this.input_message,
              masters     :   this.chat_info.masters,
              type        :   1,
          } 
          this.socket.emit('chat message', data);
          this.input_message = ''; 
      }
    }
    read_message(msg_id){
        var data = {
          chat_id     :   parseInt(this.chat_info.chat_id),
          msg_id      :   parseInt(msg_id)
        } 
        this.socket.emit('read message', data);
    }

    async OpenChatBox() {
        await this.get_messages();
        document.getElementById("Chatbox").style.opacity = "1";
        document.getElementById("Chatbox").style.transform = "translateY(0)";
        document.getElementById("Chatbox").style.zIndex = "1256";  
        this.$refs.scrollContainer.scrollTop = this.$refs.scrollContainer.scrollHeight;
        setTimeout(() => {
          this.checkNewMessageVisibility();
        },800);
  
    } 
    CloseChatBox() {
        document.getElementById("Chatbox").style.opacity = "0";
        document.getElementById("Chatbox").style.transform = "translateY(200px)";
        document.getElementById("Chatbox").style.zIndex = "-1";  
    }

    uploadChatF(event){   
      let form_data = new FormData();   
      var files = event.target.files;
      

      if (typeof files!='undefined' && files.length>0) {
        form_data.append('chat_id', parseInt(this.chat_info.chat_id)); 
        form_data.append('msg_file', files[0],files[0].name);
            
        Axios.defaults.headers.common['Access-Control-Allow-Origin']  = '*'; 
        var url =Common.api_url()+'api/upload-chat-file';  
        Axios.post(url, 
          form_data, 
          {
            headers: {  
              'Content-Type'  : 'multipart/form-data',
              'authorization' : this.token,
            }, 
            onUploadProgress: function( progressEven) {
              // var loaded:number  =  progressEvent.loaded;
              // var total:number   =  progressEvent.total;
              // var per   =    parseInt((loaded/total *100).toFixed()) ;  
            }.bind(this)
          }
        )
        .then(response => { 
          var json_data = response.data;  
          if (typeof response.data!='undefined' && typeof json_data.status!='undefined' && json_data.status==200) {
            var data = {
                chat_id     :   parseInt(this.chat_info.chat_id), 
                to_id       :   null,
                my_id       :   parseInt(this.chat_info.chat_m_id), 
                token       :   this.token,
                message     :   json_data.file_name,
                url         :   json_data.url,
                file_id     :   json_data.file_id,
                type        :   2,
                masters     :   this.chat_info.masters, 
            }  
            this.socket.emit('chat message', data); 
          }
          if (typeof (json_data.error)!="undefined"){ 
            // alert(json_data.message); 
          }  

        })
        .catch(error => {
          console.log(error);  
        });
      }else{
        alert('Please select valid document for upload.');
        return false; 
      }       

    }  
      
    


    handleScroll (event) { 
      const scrollContainer = this.$refs.scrollContainer;
      const scrollPosition = scrollContainer.scrollTop;
      
      // Calculate the distance of the scrollbar from the top
      const scrollDistanceFromTop = scrollPosition - 90;
      // console.log(scrollDistanceFromTop);
      if(scrollDistanceFromTop<=0 && this.total_page>this.page_no){
        this.page_no = this.page_no+1;
        this.get_messages();
      } 
      setTimeout(() => {
        this.checkNewMessageVisibility();
      },700);
    
      // Any code to be executed when the window is scrolled
    }
 
    checkNewMessageVisibility() {
      if( this.isTabFocused==false){
        return false;
      }
       
      this.$nextTick(() => {
        for (const message of this.unread_msgs) {
          if (!message.is_read) {
            const messageElement = this.$refs[`msg_${message.msg_id}`];
            // console.log(messageElement)
            if(this.isInViewport(messageElement)){
              message.is_read = true;
              this.read_message(message.msg_id);
              this.unread_msgs = this.unread_msgs.filter(function(item){ return item.msg_id !== message.msg_id });
            } 
          }
        }
      });
    }
    

    async get_messages(){
      await Common.get_caprock_messages(this.token,this.page_no)
          .then(async response => {
              if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                var msgs = response.data.messages; 
  
                if ( typeof msgs!='undefined' && typeof msgs.records!='undefined' && msgs.records.length>0){
                 
                  for (let i = 0; i < msgs.records.length; i++) { 


                    if(msgs.records[i]['msg_read_received']==1 ||msgs.records[i]['msg_read_received']=='1'){
                      msgs.records[i]['tick']= this.s_tick_svg; 
                    }if(msgs.records[i]['msg_read_received']==2 ||msgs.records[i]['msg_read_received']=='2'){
                      msgs.records[i]['tick']= this.s_tick_svg; 
                    }if(msgs.records[i]['msg_read_received']==3 ||msgs.records[i]['msg_read_received']=='2'){
                      msgs.records[i]['tick']= this.b_tick_svg; 
                    }   
                    msgs.records[i]['is_image']=await this.checkImageUrl(msgs.records[i]['url']);
                       
                    msgs.records[i]['time'] =moment.unix(msgs.records[i]['msg_time']).format('h:mm A'); 
                    if (msgs.records[i]['msg_user_id']!=this.chat_info['chat_m_id']){
                      if(msgs.records[i]['msg_read_received']!=3 || msgs.records[i]['msg_read_received']!='3'){
                        msgs.records[i]['is_read'] =false; 
                        this.unread_msgs.push(msgs.records[i]);
                      }else{
                        msgs.records[i]['is_read'] =true;
                      } 
                    }else{
                      msgs.records[i]['is_read'] =true;
                    }

                  }
                  if(this.page_no==1){
                    this.messages = msgs.records;
                  }else{
                    this.messages.unshift(...msgs.records);
                  }
                   
                  this.total_page = msgs.page_count;

                  setTimeout(() => {
                    this.checkNewMessageVisibility();
                  },800);
                } 
                // return true; 
              }else{
                if (typeof response.data !== 'undefined' && response.data.success == 500 && typeof response.data.screenCode !== 'undefined' && response.data.screenCode==0) { 
                  this.logout();
                } 
              }
          }).catch(e => {
            console.log(e)
      });

    }

    checkImageUrl(imageUrl) {
      const img = new Image();
      img.src = imageUrl;
      return new Promise((resolve) => {
        img.onload = () => {
          resolve(true);
        };
        
        img.onerror = () => {
          resolve(false);
        };
      }); 
    }

    start_chat(){
      Common.start_chat(this.token)
          .then(response => {
              if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                var chat = response.data.chat; 
                if (typeof chat!='undefined'){
                  this.chat_info = chat; 
                } 
              } 
          }).catch(e => {
          console.log(e)
      });
    }


    async get_chat_info(){
      await Common.get_chat_info(this.token)
          .then(response => {
              if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                var chat = response.data.chat; 
                if (typeof chat!='undefined'){
                  this.chat_info = chat; 
                } 
              } 
          }).catch(e => {
          console.log(e)
      });
    }
 

      
  } 
</script>


