
    import { Options, Vue } from 'vue-class-component';
    import HelloWorld from '@/components/HelloWorld.vue';
    import Basic from '@/components/customer/Basic.vue';
    import CurrencyInput from "@/components/CurrencyInput.vue"; 
    import Common from '@/services/common.js';
    import moment from 'moment';  
    import Axios from "axios";
    import $ from 'jquery';  
    import VueApexCharts from "vue3-apexcharts"; 
    
    import Toast from '@/components/Toast.vue'
    @Options({
        components: {
            HelloWorld, 
            Basic,
            CurrencyInput,
            'apexchart': VueApexCharts  ,
            'toast': Toast  
        }
    })

    export default class InvestmentMultiAsset extends Vue { 
        STORAGE_KEY = "caprock-local";   
        user_name = ''; 
        multi_asset_doc_url = '';
        customer_accounts   =   []; 
        docs:any =   [];  
        form      ={};
        inv_menu =   {}; 
        confirm = 0;
        target_allocation_total = 0;
        total_ac_balance    =   0;  
        is_invested    =   0;  
        local_storage:any;
        dashboard = {};
        alerts:any = [];
        errors:any = [];
        inv_id:any= 0;
        error_flag = 0;
        confirm_error = '';
        investment_currency = 'USD';

        cta_series:any =[];
        cta_chartOptions :any={};

        hp_series:any ={};
        hp_chartOptions :any={};

        target_allocations      :any = [];
        historical_performance  :any = [];

        cash_accounts :any= [];

        is_valid_cashac :any    =true; 
        do_debit_amount :any    =0; 

        amount_options = { 
            currency: this.investment_currency,
            currencyDisplay: "narrowSymbol", 
        };
        value= 1234;
        current_date = moment(new Date()).format("DD/MM/YYYY");

        // year_chart_values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        
        this_month = moment().format("MMM, YYYY");
        prev_month = moment().subtract(1, 'months').startOf('month').format("MMM, YYYY");
         
        
        created() {     
            var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
            if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
                /*console.log('Try After Sometime');*/
            }else{ 
                localStorage.removeItem(this.STORAGE_KEY); 
                location.href='/sign-in'; 
            } 
            document.title = "Investment advisor menu || Caprock Merchant Platform";
            localStorage.setItem(
                'active_page', 'inv_menu'
            );  
            return {  
                form: { 
                    e_name: '',
                    e_phone_no: '',
                    e_email: '',
                    e_dob: '',
                    curdate:'',
                    amount: '' ,
                    current_date:'',
                    cash_subac_id:''
                } 
            }
        }
        mounted(){
            this.user_name=''; 
            this.get_user_accounts(); 
            this.get_investment_advisor_data(this.$route.params.id);    
            this.get_cash_accounts();

        }

        checkBalance(){  
            if(this.is_valid_cashac==true){
                this.do_debit_amount=1;   
                this.closeModal('confirmInvestment');
                this.submitForm();  
            }else{ 
                this.closeModal('confirmInvestment'); 
                this.openModal('changeCashAc'); 
            }

        }

        get_cash_accounts(){ 
             Common.get_customer_cash_ac(this.local_storage['access-token'],'fum,securities')
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        var cash_accounts = response.data.cash_accounts;
                        var final_cash:any=[];
                        var j=0;
                        for (let i = 0; i < cash_accounts.length; i++) {  
                            if(cash_accounts[i]['is_linked']==0 || cash_accounts[i]['is_linked']=='0'){
                                final_cash[j]=cash_accounts[i]
                                j++;
                            }
                        } 
                        this.cash_accounts = final_cash; 
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        balance_cashac(subac_id,amount){  
            this.is_valid_cashac = false;

            if(typeof subac_id!='undefined' && subac_id!='' && subac_id!=null && subac_id>0 && typeof amount!='undefined' && amount!='' && amount!=null && amount>0){
                Common.balance_cash_ac(this.local_storage['access-token'],subac_id,amount)
                    .then(response => {
                        if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                            if(typeof response.data.is_valid!='undefined' && response.data.is_valid==true){
                                this.is_valid_cashac=true;
                            }
                        }
                    }).catch(e => {
                      console.log(e)
                }); 
            }
        }

        get_user_accounts() { 
            Common.get_user_accounts(this.local_storage['access-token'],1)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        var c_acs =response.data.accounts;
                        this.total_ac_balance= response.data.t_balance.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) ;   

                        for (let i = 0; i < c_acs.length; i++) {  
                            var ser= [{
                                name: 'Balance', 
                                data:c_acs[i]['ac_chart']
                            }];
                            var chartO= {
                                chart: {
                                    height: 200,
                                    type: 'area',
                                    toolbar: {
                                        show: false
                                    }
                                }, 
                                labels: {
                                    show: false,
                                },
                                grid: {
                                    show: false,
                                },
                                stroke: {
                                    curve: 'smooth',
                                    width: 2
                                },
                                xaxis: {  
                                    categories: moment.monthsShort(),
                                    labels: {
                                        show: false 
                                    } ,
                                    lines: {
                                        show: false
                                    } 
                                },
                                yaxis: { 
                                    axisBorder: {
                                        show: false
                                    },
                                    axisTicks: {
                                        show: false,
                                    }, 
                                    labels: {
                                        show: false 
                                    }  
                                },
                                colors: [c_acs[i]['ac_color']],
                                dataLabels: {
                                    enabled: false
                                }  
                            }; 

                            c_acs[i]['series']=ser;
                            c_acs[i]['chartOptions']=chartO;
                        } 
                        this.customer_accounts=c_acs;

                    }
                }).catch(e => {
                  console.log(e)
            });
        }
         
        get_investment_advisor_data(id) { 
            this.inv_id=id;
            Common.get_inv_adv_menu(this.local_storage['access-token'],id)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        var inv_menu                =   response.data.inv_adv_menu; 
                        inv_menu['currency']        =   'AUD';

                        var target_allocation_total = 0 ;
                        this.target_allocations     =    inv_menu['ms_target_allocation'];
                        this.historical_performance =    inv_menu['ms_historical_performance'];



                        /*TARGET ALLOCATION CHART*/
                            var tg_a            =   inv_menu['ms_target_allocation'];  
                            var cta_series:any  =   [];
                            var cta_titles:any  =   [];
                            for (let i = 0; i < tg_a.length; i++) {    
                                if( (typeof tg_a[i]['t'] !='undefined' &&  tg_a[i]['t']!=null &&  tg_a[i]['t']!='') && (typeof tg_a[i]['a'] !='undefined' &&  tg_a[i]['a']!=null &&  tg_a[i]['a']!='')){ 
                                    cta_titles[i] =  tg_a[i]['t'];
                                    cta_series[i] =  parseFloat(tg_a[i]['a']);

                                    target_allocation_total = target_allocation_total + cta_series[i] ;
                                } 
                            }   
                            this.target_allocation_total = target_allocation_total;
                            this.cta_series = cta_series;
                            this.cta_chartOptions={
                                chart: {
                                    type: 'donut',
                                    height:200
                                }, 
                                labels: cta_titles, 
                                legend: {
                                    show: true,
                                    position: 'bottom',
                                    horizontalAlign: 'center', 
                                },       
                            }; 
                        /*TARGET ALLOCATION CHART END*/


                        /*Historical Perfomance CHART*/
                            var hp_a            =    inv_menu['ms_historical_performance']; 
                            var hp_series:any  =   [];
                            var hp_titles:any  =   []; 
                            var hp_colors:any  =   []; 
                            for (let i = 0; i < hp_a.length; i++) {  
                                if( (typeof hp_a[i]['k'] !='undefined' &&  hp_a[i]['k']!=''&&  hp_a[i]['k']!=null) && (typeof hp_a[i]['v'] !='undefined' &&  hp_a[i]['v']!='' &&  hp_a[i]['v']!=null)){
                                    hp_titles[i] =  hp_a[i]['k'];
                                    hp_series[i] =  parseFloat(hp_a[i]['v']); 
                                    hp_colors[i] = '#1a3b8b'; 
                                } 
                            }     
                            this.hp_series= [{
                                name: 'Growth',
                                data: hp_series
                            }]; 
                            this.hp_chartOptions= {
                                chart: {
                                  height: 400,
                                  type: 'line',
                                  zoom: {
                                    enabled: false
                                  }
                                },
                                colors : hp_colors,
                                dataLabels: {
                                  enabled: false
                                },
                                stroke: {
                                  curve: 'straight'
                                },
                                title: {
                                    text: 'Caprock Growth SMA 80/20',
                                    align: 'center'
                                },
                                grid: {
                                  row: {
                                    colors: ['#f3f3f3', 'transparent'],  
                                    opacity: 0.5
                                  },
                                },
                                xaxis: {
                                  categories: hp_titles,
                                }
                            }; 
                        /*Historical Perfomance CHART END*/
 
                        inv_menu['ms_strategy_inception_date'] =   moment(inv_menu['ms_strategy_inception_date']).format('DD/MM/YYYY');
 
                        if(typeof inv_menu['ms_replace_logo']!='undefined' && (inv_menu['ms_replace_logo']==1 || inv_menu['ms_replace_logo']=='1')){
                            inv_menu['chart_label'] = inv_menu['ms_subadvisor'];
                        }else{
                            inv_menu['chart_label'] = 'Caprock Funds Management '
                        }



                        this.inv_menu               =   inv_menu; 



                        this.docs       =   response.data.documents;     
                        this.investment_currency = this.inv_menu['currency'];  
                        this.amount_options = { 
                            currency: this.investment_currency,
                            currencyDisplay: "narrowSymbol"
                        }; 
                        document.title = this.inv_menu['inv_name']+" || Caprock Merchant Platform"; 
                        if(response.data.investment!=0){
                            this.is_invested = response.data.investment; 
                        }  
                    } 
                    if(response.data.inv_adv_menu.length==0){
                        location.href="/";  
                    }
                }).catch(e => {
                  console.log(e)
            });
        }

        participate_inv_adv_menu(id){ 
            (this.$refs['toast'] as any).pLoadOn();  
            Common.participate_inv_adv_menu(this.local_storage['access-token'],id)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        (this.$refs['toast'] as any).prdipToast( 
                            'Success',
                            'You are participated to this menu',
                            1200,
                        ); 
                        setTimeout(() => { 
                            location.reload();
                        }, 1200) 

                    }else{
                        (this.$refs['toast'] as any).prdipToast( 
                            'Warning',
                            'Failed to participate in this menu',
                            '',
                        ); 
                    }  
                    (this.$refs['toast'] as any).pLoadOff();  
                }).catch(e => {
                  console.log(e)
            });
        }

        validateForm(){  
            this.form['current_date']=moment(new Date()).format("YYYY-MM-DD"); 
            var js = JSON.parse(JSON.stringify((this.form)));   
            this.errors = [];
            this.error_flag=0;
            var required = [
              { 'key': 'e_name', 'name': 'Your Name' }, 
              { 'key': 'e_email', 'name': 'Email' }, 
              { 'key': 'e_phone_no', 'name': 'Phone No' },
              { 'key': 'e_dob', 'name': 'Date Of Birth' }, 
              { 'key': 'amount', 'name': 'Invested Amount' } 
            ];
            var valid_emails = [ 
              { 'key': 'e_email', 'name': 'E-Signature Email' }
            ];

            var valid_phones = [ 
              { 'key': 'e_phone_no', 'name': 'E-Signature Phone' }
            ]; 
             
            var new_errors: any = []; 
            for (let i = 0; i < valid_emails.length; i++) {
                this.validEmail(valid_emails[i]['key'], valid_emails[i]);
            }
            for (let i = 0; i < required.length; i++) {
                this.requiredInput(required[i]['key'], required[i]);
            } 
            let form_data = new FormData();  
            if(this.confirm==0){
                this.confirm_error="Please agree to condition.";
                this.error_flag = 1;
            }else{
                this.confirm_error="";
            } 
            if(this.error_flag != 0){    
                (this.$refs['toast'] as any).prdipToast( 
                    'Warning',
                    'Please fill all the required fields first',
                    '',
                    false
                );
                $('#subBtn').removeAttr('disabled');
                $('#subBtn').text('Invest Now');
                return false; 
            }else{
                this.form['cash_subac_id']='';
                this.openModal('confirmInvestment');
            } 
                 
        }  
        submitForm(){  
            this.form['current_date']=moment(new Date()).format("YYYY-MM-DD");
            // $('#subBtn').attr('disabled', 'disabled');
            // $('#subBtn').text('Processing ... ');
            var js = JSON.parse(JSON.stringify((this.form)));  
            /*Validation Check*/
            this.errors = [];
            this.error_flag=0;
            var required = [
              { 'key': 'e_name', 'name': 'Your Name' }, 
              { 'key': 'e_email', 'name': 'Email' }, 
              { 'key': 'e_phone_no', 'name': 'Phone No' },
              { 'key': 'e_dob', 'name': 'Date Of Birth' },
              { 'key': 'amount', 'name': 'Invested Amount' }, 
            ];
            var valid_emails = [ 
              { 'key': 'e_email', 'name': 'E-Signature Email' }
            ];

            var valid_phones = [ 
              { 'key': 'e_phone_no', 'name': 'E-Signature Phone' }
            ]; 
            
            var new_errors: any = []; 
            for (let i = 0; i < valid_emails.length; i++) {
                this.validEmail(valid_emails[i]['key'], valid_emails[i]);
            }
            for (let i = 0; i < required.length; i++) {
                this.requiredInput(required[i]['key'], required[i]);
            }
             
            let form_data = new FormData();  
            if(this.confirm==0){
                this.confirm_error="Please agree to condition.";
                this.error_flag = 1;
            }else{
                this.confirm_error="";
            }


            if(this.error_flag == 0){  
                for (var key in js) {
                  form_data.append(key, js[key]);
                }
                $('#subBtn').attr('disabled','disabled');
                $('#subBtn').text('Processing ... '); 
                form_data.append('user_token', this.local_storage['access-token']);         
                form_data.append('inv_id', this.inv_id);
                form_data.append('do_debit_amount', this.do_debit_amount);
                form_data.append('inv_type', '2'); /*Multi Asset*/


                var object = {};
                form_data.forEach((value, key) => object[key] = value);
                var json = JSON.stringify(object);
                console.log(json);

                Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; 
                var url =Common.base_url()+'customer-multi-asset-investment-application';
                Axios.post(url, 
                  form_data, 
                  {
                    headers: {  
                      'Content-Type': 'multipart/form-data'
                    }, 
                    onUploadProgress: function( progressEvent:any ) {
                      var loaded:number  =  progressEvent.loaded;
                      var total:number   =  progressEvent.total;
                      var per   =    parseInt((loaded/total *100).toFixed()) ;  
                    }.bind(this)
                  }
                )
                .then(response => { 
                  if(response.data.success==200){    
                    (this.$refs['toast'] as any).prdipToast( 
                        'Sucess',
                        response.data.message,
                        '', 
                        false /*Reload TRUE FALSE*/
                    ); 
                    setTimeout(() => { 
                      this.$router.push('/'); 
                    }, 4000) 

                    $('#subBtn').removeAttr('disabled');
                    $('#subBtn').text('Submit Now'); 
                  }else{ 
                    (this.$refs['toast'] as any).prdipToast( 
                        'Warning',
                        response.data.message,
                        '',
                        false /*Reload TRUE FALSE*/
                    ); 
                    // location.reload();
                    $('#subBtn').removeAttr('disabled');
                    $('#subBtn').text('Invest Now');
                  } 
                })
                .catch(error => {
                  $('#subBtn').removeAttr('disabled');
                  $('#subBtn').text('Invest Now'); 
                });
            }else{ 
                (this.$refs['toast'] as any).prdipToast( 
                    'Warning',
                    'Please fill all the required fields first',
                    '',
                    false
                );
                $('#subBtn').removeAttr('disabled');
                $('#subBtn').text('Invest Now');
                return false; 
            } 
                 
        }  

        checkPhone(event: any) {
            var tag_name = event.target.name;

            var ph = event.target.value
            var valid = 1;
            var error_msg = 'Please enter valid phone number';


            if (ph.length < 7 || ph.length > 12) {
                valid = 0;
                error_msg = 'Phone number length is not valid';
            }
            if (isNaN(ph)) {
                valid = 0;
                error_msg = 'Only number character is allowed';
            }
            if (ph == '') {
                valid = 0;
                error_msg = 'Please enter phone number';
            }
     
            $('.' + tag_name + 'err').html('');
            if (valid ==0) {
                $("#" + tag_name).before('<span class="' + tag_name + 'err errorMsg danger ml-2 float-right">' + error_msg + '</span>');
            }
        } 
        validEmail(e_id, obj: any) {
            var tag_name = $("#" + e_id).attr("name");
            var e_val = $("#" + e_id).val();
            var valid = 1
            var error_msg = 'Please enter valid email address';
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (typeof obj !== "undefined" && obj !== null) {
                error_msg = 'Please enter a valid email address for field - ' + obj.name;
            }
            if (!re.test(String(e_val).toLowerCase())) {
                valid = 0;
            }
            $('.' + tag_name + 'err').html('');
            if (valid == 0) {
                this.error_flag=1;
                $("#" + e_id).before('<span class="' + tag_name + 'err errorMsg danger ml-2 float-right">' + error_msg + '</span>');
            }
        }

        requiredInput(r_id, obj: any) {
            var tag_name = $("#" + r_id).attr("name");
            var r_val = $("#" + r_id).val();

            var valid = 1;
            var em = 'Field required';

            if (typeof r_val == undefined || r_val == '' || r_val == null) {
                em = tag_name + ' is required';
                valid = 0;
            }
            if (typeof(obj) !== "undefined" && obj != null) {
              em = obj.name+ ' is required';
            }

            if (valid == 0) {
                this.error_flag=1;
                $('.' + tag_name + 'err').html('');
                $("#" + r_id).before('<span class="' + tag_name + 'err errorMsg danger ml-2 float-right">' + em + '</span>');
            }
        }
        requiredRadio(r_id, obj: any) {
            var tag_name = $('input[name="'+r_id+'"]').attr("name");
            var r_val = this.form[r_id];
             
            var valid = 1;
            var em = 'Field required';

            if (typeof r_val == undefined || r_val == '' || r_val == null) {
                em = 'Field required';
                valid = 0;
            } 
            if (valid == 0) {
                this.error_flag=1;
                $('.' + tag_name + 'err').html('');
                $('#'+r_id+'_label').append('<span class="' + tag_name + 'err errorMsg danger ml-2 float-right">' + em + '</span>');
            }
        }

        remove_err(event:any){ 
          $('.'+event.currentTarget.id+'err').remove();
        }
        removeTagErr(event: any) {
          var tag_name = event.target.name;
          $('.' + tag_name + 'err').html('');
        }

        openModal(id: any) {
          $('#' + id).addClass('d-block');
          $('#' + id).addClass('show');
          $('#' + id).css('overflow-y', 'scroll');
        }
        closeModal(id: any,reload=false) {
          $('#' + id).removeClass('d-block');
          $('#' + id).addClass('d-none');
          $('#' + id).removeClass('show');
          if(reload==true){
            location.reload();
          }
        } 
 

    }
