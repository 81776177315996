
  import { Options, Vue } from 'vue-class-component'; 
  import $ from 'jquery';
  import 'bootstrap'; 
  @Options({
    props: {
      msg: String
    }
  })
  export default class Toast extends Vue {
    msg!: string
    STORAGE_KEY     =   "caprock-local";  
    token           =   '';
    load_on         =   0;
    toast_title     =   "";
    toast_message   =   "";
    reload = false;

    session = JSON.parse(localStorage.getItem(this.STORAGE_KEY) || "{}");
    data () {     
      if(this.session){
        var js =this.session;
        this.token=js['access-token'];   
      }else{
        this.token='';  
        this.logout();
      } 
      return {  
        token:this.token,
        toast_title:this.toast_title,
        toast_message:this.toast_message
      }
    }    
    created() {    
      return true;
    }
    logout(){
      localStorage.clear(); 
      this.token='';   
      location.href='/sign-in'; 
    } 
    openModal(id: any) {
      $('#' + id).addClass('d-block');
      $('#' + id).addClass('show');
      $('#' + id).css('overflow-y', 'scroll');
    }
    closeModal(id: any,reload=false) {
      $('#' + id).removeClass('d-block');
      $('#' + id).addClass('d-none');
      $('#' + id).removeClass('show');
      if(reload==true){
        location.reload();
      }
    } 

    prdipToast(title:any,message:any,timer:any='',reload=false){ 
      this.toast_title    =   title;
      this.toast_message  =   message; 
      
      $('#toastLabell').html(this.toast_title);
      $('#toastBodyy').html(this.toast_message); 
      this.openModal('toastJam');    
      if(timer!='' && timer>0){
        setTimeout(() => { 
          this.closeModal('toastJam');  
        }, timer) 
      } 
      if(reload ==true ){
        this.reload = true;
      } 
    } 

    pLoadOn(){ 
      this.load_on = 1; 
      /*console.log(this.load_on);*/
    } 
    pLoadOff(){ 
      this.load_on = 0; 
      /*console.log(this.load_on)*/
    } 
  }
