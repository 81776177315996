
  import { Options, Vue } from 'vue-class-component';
  import $ from 'jquery';
  import HelloWorld from '@/components/HelloWorld.vue';
  import Basic from '@/components/customer/Basic.vue';
  import Rightside from '@/components/customer/Rightside.vue';
  import Common from '@/services/common.js'; 
  import moment from 'moment';

  import Toast from '@/components/Toast.vue'; 
  import Axios from "axios";   


 
  @Options({
    components: {
        'toasti':Toast, 
        HelloWorld, 
        'right-side':Rightside,
        Basic
    },
  })
  export default class Documents extends Vue { 
    STORAGE_KEY = "caprock-local";   
    user_name = ''; 
    user_info = {}; 
    token='';
    customer_accounts   =   []; 
    total_ac_balance    =   0; 
    docs :any =  [];
    local_storage:any;
    dashboard = {
        currencies:[]
    }; 
    timer :any= undefined;
    
    data () {        
        return {  
            customer_accounts   :this.customer_accounts,
            user_info           :this.user_info   
        } 
    }   
        
    created() {     
        var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
        if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
            // console.log('My Documents');
            this.token=js['access-token'];
        }else{  
            localStorage.removeItem(this.STORAGE_KEY); 
            this.$router.push('/sign-in'); 
        } 
        document.title = "My Documents || Caprock Merchant Platform";
        localStorage.setItem(
            'active_page', 'documents'
        ); 
    }
    mounted(){
        this.user_name=''; 
        this.get_user_accounts(); 
        this.get_user_info(); 
        this.get_documents('');  
    }
    open_link(id){
        var link = (document.getElementById("URL"+id) as HTMLInputElement).value; 
        window.open(link,'_blank');
    }

    get_user_accounts() { 
        Common.get_user_accounts(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                    this.customer_accounts=response.data.accounts;
                    this.total_ac_balance   =   response.data.t_balance.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) ;  
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_user_info() { 
        Common.get_user_info(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    var user_inf=response.data.profile; 
                    user_inf.join_date=user_inf.join_date?moment(user_inf.join_date).format('DD MMM YYYY '):'';
                    this.user_info=user_inf;
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_documents(search) {   
        Common.get_documents(this.local_storage['access-token'],search)
            .then(response => {
                this.docs=[]; 
                if (typeof response.data !== 'undefined' && response.data.success == 200) {    
                    var sdocs =response.data.docs;

                    for (let i = 0; i < sdocs.length; i++) {                           
                        sdocs[i]['doc_time'] =moment(sdocs[i]['created_at']).format('DD MMM, YYYY'); 
                    } 
                    this.docs=sdocs;
                }
            }).catch(e => {
              console.log(e)
        });
    }

    textSearch(e:any){
        clearTimeout(this.timer) 
        this.timer = setTimeout(() => {
            this.get_documents(e.target.value)
             
        }, 300)
    }

    mail_doc(doc_id, to, type){ 
        (this.$refs['toasti'] as any).openModal('sending_email');
        if(parseInt(doc_id)==0 || doc_id==''){
            // (this.$refs['toasti'] as any).prdipToast( 
            //     'Warning',
            //     'Please select valid document from list',
            //     '',
            // );
            alert('Please select valid document from list');
          return false;
        }
         
 
        let form_data = new FormData();
        form_data.append('device', 'vuew');  
        form_data.append('user_token', this.token);
        form_data.append('doc_id', doc_id);
        form_data.append('to', '1');
        form_data.append('ttype', 'doc');

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        var url = Common.base_url()+'send-doc-mail'; 
        Axios.post(url,
            form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: function(progressEvent: any) {
                    var loaded: number = progressEvent.loaded;
                    var total: number = progressEvent.total;
                    var per = parseInt((loaded / total * 100).toFixed());
                }.bind(this)
            }
        )
        .then(response => {
            var title = '';
            (this.$refs['toasti'] as any).closeModal('sending_email');
            if (response.data.success == 200) {
              /*  alert(response.data.message);*/
                title = 'Success'; 
                /*(this.$refs['toasti'] as any).prdipToast( 
                    title,
                    response.data.message,
                    '',
                    true
                );*/
                alert(response.data.message);
                // setTimeout(() =>this.$router.push('/'),3500);
            } else {
                alert(response.data.message);
                title = 'Failed';
                /*(this.$refs['toasti'] as any).prdipToast( 
                    title,
                    response.data.message,
                    '',
                    false
                ); */ 
            }   
        })
        .catch(error => { 
            return true;
        });
         


    }



    


  }
