
  import { Options, Vue } from 'vue-class-component';   
  import Common from '@/services/common.js';  
  import App from '../App.vue';  
  import $ from 'jquery';   
  import Basic from '@/components/Basic.vue';    
  
  import { Form, Field,ErrorMessage } from 'vee-validate';
    import { useToast } from "vue-toastification";

  import Axios from "axios";
  @Options({
    components: {  
      Basic , 
      Form,
      Field,
      ErrorMessage
    }, 
  })

  export default class Onboard extends Vue { 
    load_on:any     =   0;
    prev_path :any  =   ''; 
    STORAGE_KEY     =   "caprock-local";   

    applicant_type:any  =  ''; 
    company_type:any  =  ''; 
    toast:any = useToast();
    local_storage:any;
    in_verification : any = 0;
    in_onboarding : any=0;
    
    is_director     : any=0;
    
    data () {     
      return {  
        form: {
          user_email        : '',
          user_password     : ''
        },
        load_on:this.load_on, 
        is_director:this.is_director, 
        applicant_type : this.applicant_type, 
        company_type : this.company_type
      }
    }   
    

    beforeCreate(){
      this.load_on=1;
    }

    created() {     
      setTimeout(() => this.load_on=0, 500); 
      var js  = this.local_storage =   JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));   
      if(typeof js!=='undefined' && typeof js['access-token']!=='undefined' && js['access-token']!=''){ 
        var system_access   =   localStorage.getItem('system_access'); 
        if(typeof(system_access)!=='undefined' && system_access=='1'){   
            this.$router.push('/');  
        }else{  
            Common.get_user_info(js['access-token']).then(response => {   
                var ui_type  = response.data.profile.u_type
                 
                 
                if(ui_type!=200 && ui_type!='200'){    
                    this.in_onboarding =ui_type;
                } 

                if(typeof response.data.profile.company_type!='undefined' && response.data.profile.company_type!='' && response.data.profile.company_type!=null){    
                    this.company_type =response.data.profile.company_type;
                } 

                if(response.data.success==200){
                    if(typeof response.data.profile.user_is_director!='undefined'){
                        this.is_director = response.data.profile.user_is_director;
                        if(this.is_director==1){
                            this.applicant_type=1;
                        }
                    } 
                }


                Common.get_active_onboarding_reg_step(this.local_storage['access-token'],1)
                .then(response => { 
                    if (typeof response.data !== 'undefined' && response.data.success == 200 && response.data.old_request ==1) {
                        var req=response.data.onboarding_stage;  

                        if(req['reg_status']==1){
                            this.in_verification=1;
                            this.$router.push('registration-complete');
                        }  
                        if(this.in_onboarding!=0 && this.in_verification==0){
                            this.process_step(this.in_onboarding,this.company_type);
                        }   
                    }else{ 
                        this.$router.push('sign-in');
                    }
                }).catch(e => {
                    console.log(e)
                    return true;
                });


                
                // return true;
            }).catch(e => {console.log(e) }); 
        }  
      }else{  
        this.$router.push('/sign-in');  
      }  

      


      document.title = 'Onboard || Caprock Merchant Platform';   
      return true;
    } 


    validateEmail(value) { 
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (typeof value!='undefined' && !regex.test(value)) { 
            return 'This field must be a valid email'; 
        } 
        return true;
    }  
    requiredField(value) { 
        if (!value) {
            return 'This field is required';
        } 
        return true;
    }
    selectField(value) { 
        if (!value || value=='') {
            return 'Please select one of option';
        } 
        return true;
    }
    mobileField(value){ 
        if(typeof value!='undefined'){
            var phoneNum = value.replace(/[^\d]/g, '');
            if(phoneNum.length <7 || phoneNum.length > 12) {  
                return 'This field must be a valid mobile number';
            }  
            return true;
        }
        return true; 
    } 


    app_proceed(){  
        this.load_on=1;  
        let form_data       = new FormData();  
        form_data.append('user_token'           ,   this.local_storage['access-token']); 
        form_data.append('user_type'            ,   this.applicant_type); 
      
        
        var message = '';
        var error   = 0; 
        if(error!=0){
            setTimeout(() => this.load_on=0, 500);  
            this.toast.error(message);
        }else{  
            Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            var url =Common.base_url()+'user-applicant-type-update';
            Axios.post(
                url, 
                form_data, 
                {
                    headers: {  
                      'Content-Type': 'multipart/form-data'
                    }, 
                    onUploadProgress: function( progressEvent:any ) {
                        var loaded:number   =   progressEvent.loaded;
                        var total:number    =   progressEvent.total;
                        var per             =    parseInt((loaded/total *100).toFixed());  
                    }.bind(this)
                }
            )
            .then(response => { 
                if(response.data.success==200){ 
                    var u_type = response.data.user_type;    
                    this.process_step(u_type); 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.success(response.data.message);
                }else{ 
                  setTimeout(() => this.load_on=0, 500);  
                  this.toast.error(response.data.message);
                } 
            })
            .catch(error => { 
                setTimeout(() => this.load_on=0, 500);  
                this.toast.error('Internal Error');
            });  
        }  
       
    }


    process_step(applicant_type,company_type=null){ 
        if(applicant_type==1 || applicant_type=='1'){
            this.$router.push('/individual-app');  
        }
        if(applicant_type==2 || applicant_type=='2'){
            if(company_type!=null){
                if(company_type==1){
                    this.$router.push('/australian-domestic-app');  
                }
                if(company_type==2){
                    this.$router.push('/asic-non-australian-app');  
                }
                if(company_type==3){
                    this.$router.push('/non-australian-app');  
                }
            }
        }  
        if(applicant_type==3 || applicant_type=='3'){
            this.$router.push('/trust-app');  
        } 
        if(applicant_type==4 || applicant_type=='4'){
            this.$router.push('/agent-app');  
        } 
        if(applicant_type==5 || applicant_type=='5'){
            this.$router.push('/association-app');  
        } 
        if(applicant_type==6 || applicant_type=='6'){
            this.$router.push('/govt-bodies-app');  
        } 
        if(applicant_type==7 || applicant_type=='7'){
            this.$router.push('/partnership-app');  
        } 
        if(applicant_type==8 || applicant_type=='8'){
            this.$router.push('/registered-co-operative-app');  
        } 
        if(applicant_type==9 || applicant_type=='9'){
            this.$router.push('/politically-exposed-app');  
        } 
    } 
  } 
