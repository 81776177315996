
    import { Options, Vue } from 'vue-class-component';
    import Common from '@/services/common.js';
    import $ from 'jquery';
    import 'bootstrap';  
    import Basic from '@/components/Basic.vue';  
    import Toast from '@/components/Toast.vue';  
    import Axios from "axios";    
    import { Form, Field,ErrorMessage } from 'vee-validate';
    import { useRoute } from 'vue-router'
    import {ref } from 'vue';


    @Options({
        components: {
            Basic,
            Form,
            Field,
            ErrorMessage

        }, 
    })
    
    export default class Signup extends Vue { 
        active_tab_name     =   'Individual Application Form';
        active_tab          =   1;
        active_step         =   1;

        download_clicked:any  = 0;

        toast_title     = "";
        toast_message   = "";

        timer :any          = undefined;
        place_suggestion = false;
        place_list:any[]= []; 
        errors: string[] = [];
        load_on:any =0;
        error_flag =0;
         

        form = {};
        countries = []; 
        states = [];
        cities = [];
        STORAGE_KEY = "caprock-local";

        url_paras = {};

        business_mail:any="";
        partner_mail :any = "";
          
        beforeCreate(){
            this.load_on=1;
        }
        data() {
            Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  

            const route         =   useRoute() 
            this.url_paras      =   route.query;   
            this.business_mail  =   this.url_paras['business_mail']; 
            this.partner_mail   =   this.url_paras['partner_mail']; 
            return { 
                timer: this.timer, 
                errors: this.errors,   
                error_flag:this.error_flag,
                load_on:this.load_on,

                 
                form: {
                    is_director     :   '', 
                    director_id     :   '',

                    is_partner     :   '', 
                    partner_id     :   '', 
                    
                    user_email      :   '',  
                    first_name      :   '',
                    last_name       :   '',
                    company_name    :   '',
                    phone_no        :   '',
                    company_type    :   '',
                    applicant_type  :   '',
                    user_type       :   this.active_tab, 
                },


                toast_title     : this.toast_title,
                toast_message   : this.toast_message,
            }  
        } 
        created() { 
            var js = JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));
            if (typeof js !== 'undefined' && typeof js['access-token'] !== 'undefined' && js['access-token'] != '') {
                this.$router.push('/');
            }   
            setTimeout(() => this.load_on=0, 500);
            document.title = "Open Account || Caprock Merchant Platform";    

            if (typeof this.business_mail!='undefined' && this.business_mail!=''){
                this.get_new_director(); 
            }
            if (typeof this.partner_mail!='undefined' && this.partner_mail!=''){
                this.get_new_partner(); 
            }
        }

        get_new_director() { 
            if(typeof this.business_mail!='undefined' && this.business_mail!=''){
                Common.get_new_director_info(this.business_mail)
                  .then(response => {
                        if (typeof response.data !== 'undefined' && response.data.success == 200) {    
                            var director_info           =   response.data.director_info; 
                            this.form['last_name']      =   director_info['director_name'].split(' ').slice(0, -1).join(' ');
                            this.form['first_name']     =   director_info['director_name'].split(' ').slice(-1).join(' ');
                            this.form['user_email']     =   director_info['director_email'];
                            this.form['phone_no']       =   director_info['director_phone_no']; 
                            this.form['director_id']    =   director_info['director_id']; 
                            this.form['is_director']    =   1;  

                        } 
                  }).catch(e => {
                    console.log(e)
                }); 
            }
        }

        get_new_partner() { 
            if(typeof this.partner_mail!='undefined' && this.partner_mail!=''){
                Common.get_new_partner_info(this.partner_mail)
                  .then(response => {
                        if (typeof response.data !== 'undefined' && response.data.success == 200) {    
                            var partner_info            =   response.data.partner_info; 
                            this.form['last_name']      =   partner_info['partner_name'].split(' ').slice(0, -1).join(' ');
                            this.form['first_name']     =   partner_info['partner_name'].split(' ').slice(-1).join(' ');
                            this.form['user_email']     =   partner_info['partner_email'];
                            this.form['phone_no']       =   partner_info['partner_phone_no']; 
                            this.form['partner_id']     =   partner_info['partner_id']; 
                            this.form['is_partner']     =   1;   
                        } 
                  }).catch(e => {
                    console.log(e)
                }); 
            }
        }




        
        submitForm(values) {  
            let form_data = new FormData();  
            for (var key in values) {
                if (typeof values[key]!='undefined'){
                    form_data.append(key, values[key]); 
                }else{
                    form_data.append(key, ''); 
                } 
            }   
 
            Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            var url         = Common.base_url()+'onboard-registration';  
            Axios.post(url,
                form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent: any) {
                        var loaded: number  = progressEvent.loaded;
                        var total: number   = progressEvent.total;
                        var per             = parseInt((loaded / total * 100).toFixed());
                    }.bind(this)
                }
            )
            .then(response => {
                var title   =   '';
                var msg     =   '';
                if (response.data.success == 200) { 
                    title = 'Success'; 
                    // (this.$refs['toast'] as any).prdipToast( 
                    //     title,
                    //     response.data.message,
                    //     '',
                    //     false
                    // );     
                    msg = response.data.message;
                    if(typeof msg !=='undefined' && msg!=null){
                        alert(msg);  
                    }                
                    setTimeout(() =>this.$router.push('/'),1000);
                } else { 
                    // title = 'Failed';
                    // (this.$refs['toast'] as any).prdipToast( 
                    //     title,
                    //     response.data.message,
                    //     '',
                    //     false
                    // );
                    msg = response.data.message;
                    if(typeof msg !=='undefined' && msg!=null){
                        alert(msg);  
                    }            
                    $('#subBtn').removeAttr('disabled');
                    $('#subBtn').text('Get Started');
                }   
            })
            .catch(error => { 
                var msg     =   '';
                // console.log('Error', error.response.data);   
                // (this.$refs['toast'] as any).prdipToast( 
                //     'Eror',
                //     error.response.data.message,
                //     '',
                //     false
                // );
                msg = error.response.data.message;
                if(typeof msg !=='undefined' && msg!=null){
                    alert(msg);  
                }             
                $('#subBtn').removeAttr('disabled');
                $('#subBtn').text('Get Started');
            }); 
            
        } 


        validateEmail(value) { 
            if (!value) {
                return 'This field is required';
            } 
             const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return 'This field must be a valid email';
            } 
            return true;
        }  
        requiredField(value) { 
            if (!value) {
                return 'This field is required';
            } 
            return true;
        }
        mobileField(value){
            if (!value) {
                return 'This field is required';
            } 
            var phoneNum = value.replace(/[^\d]/g, '');
            if(phoneNum.length <7 || phoneNum.length > 12) {  
                return 'This field must be a valid mobile number';
            } 
            return true; 
        }


  
        openModal(id: any) {
            $('#' + id).addClass('d-block');
            $('#' + id).addClass('show');
            $('#' + id).css('overflow-y', 'scroll');
        }
        closeModal(id: any) {
            $('#' + id).removeClass('d-block');
            $('#' + id).addClass('d-none');
            $('#' + id).removeClass('show');
        }

        scrollToTop() {
            var elmnt:any = document.getElementById('app'); 
            elmnt.scrollIntoView({behavior:'smooth'});
        }
 

    } 
